import React, { useState, useEffect } from 'react'
import Logo from '../Logo/Logo';
import {
	NavContainer,
	Nav,
	SubNavMenu,
	SubNavMenuList,
	SubNavMenuListItem, 
	Hamburger, 
	Bar, 
	SideBar,
	SubNavMenuListMobile
} from '../../components/Navigation/Style';
import { BodyContainer } from '../StyledComponents/StyledComponents';

const Navigation = (props) => {

	const { page } = props;
	const opacity = props.page === 'work' 
		? (window.scrollY == 0 ? 0 : .5)
		: 1
		/*(this.state ? Math.min(window.scrollY/window.innerHeight, 1) : 0) 
		: 1;*/
	
	const [slide, toggleSlide] = useState(false);

	return (
			<NavContainer opacity={opacity}>
				<BodyContainer>
					<Nav>
						<Logo color={props.logoColor} size="36px" />
						<SubNavMenu>
							<SubNavMenuList>
								<SubNavMenuListItem opacity={opacity} active={page === 'about' ? true : false}><a href="/about">About</a></SubNavMenuListItem>
								<SubNavMenuListItem opacity={opacity} active={page === 'books' ? true : false}><a href="/books">Books</a></SubNavMenuListItem>
								{/*<SubNavMenuListItem opacity={opacity} active={page === 'music' ? true : false}><a href="/music">Music</a></SubNavMenuListItem>*/}
								<SubNavMenuListItem opacity={opacity} active={page === 'hiking' ? true : false}><a href="/hiking">Hiking</a></SubNavMenuListItem>
								<SubNavMenuListItem opacity={opacity} active={page === 'travels' ? true : false}><a href="/travels">Travels</a></SubNavMenuListItem>
								{/* <SubNavMenuListItem opacity={opacity} active={page === 'music' ? true : false}><a href="/music">Music</a></SubNavMenuListItem> */}
								<SubNavMenuListItem opacity={opacity} active={page === 'blog' ? true : false}><a href="/blog">Thoughts</a></SubNavMenuListItem>
							</SubNavMenuList>
							<SubNavMenuListMobile>
								<Hamburger slide={slide} onClick={() => toggleSlide(!slide)}>
									<div
										style={{
										width: 40,
										height: 40,
										borderRadius: 20,
										marginLeft: -5,
										marginTop: -4,
										position: "absolute",
										zIndex: 1,
										}}
									></div>
									<Bar
										num={0}
										rotate1={slide && true}
										slide={slide}
										style={{ position: "relative", zIndex: 2 }}
									/>
									<Bar
										num={2}
										rotate2={slide && true}
										slide={slide}
										style={{ position: "relative", zIndex: 3 }}
									/>
									<Bar
										num={1}
										rotate3={slide && true}
										slide={slide}
										style={{ position: "relative", zIndex: 2 }}
									/>
									</Hamburger>
							</SubNavMenuListMobile>
						</SubNavMenu>
					</Nav>
					<SideBar show={slide}>
						<SubNavMenuListItem opacity={opacity} active={page === 'about' ? true : false}><a href="/about">About</a></SubNavMenuListItem>
						<SubNavMenuListItem opacity={opacity} active={page === 'books' ? true : false}><a href="/books">Books</a></SubNavMenuListItem>
						{/*<SubNavMenuListItem opacity={opacity} active={page === 'music' ? true : false}><a href="/music">Music</a></SubNavMenuListItem>*/}
						<SubNavMenuListItem opacity={opacity} active={page === 'hiking' ? true : false}><a href="/hiking">Hiking</a></SubNavMenuListItem>
						<SubNavMenuListItem opacity={opacity} active={page === 'travels' ? true : false}><a href="/travels">Travels</a></SubNavMenuListItem>
						{/* <SubNavMenuListItem opacity={opacity} active={page === 'music' ? true : false}><a href="/music">Music</a></SubNavMenuListItem> */}
						<SubNavMenuListItem opacity={opacity} active={page === 'blog' ? true : false}><a href="/blog">Thoughts</a></SubNavMenuListItem>
					</SideBar>
			{/*<Hamburger slide={slide} onClick={() => toggleSlide(!slide)}>
				<div
					style={{
					backgroundColor: "rgb(240,240,240)",
					width: 40,
					height: 40,
					borderRadius: 20,
					marginLeft: -5,
					marginTop: -4,
					position: "absolute",
					zIndex: 1,
					}}
				></div>
				<Bar
					num={0}
					rotate1={slide && true}
					slide={slide}
					style={{ position: "relative", zIndex: 2 }}
				/>
				<Bar
					num={2}
					rotate2={slide && true}
					slide={slide}
					style={{ position: "relative", zIndex: 3 }}
				/>
				<Bar
					num={1}
					rotate3={slide && true}
					slide={slide}
					style={{ position: "relative", zIndex: 2 }}
				/>
				</Hamburger>

				<SideBar show={slide}>
					<SubNavMenuListItem opacity={opacity} active={page === 'about' ? true : false}><a href="/about">About</a></SubNavMenuListItem>
					<SubNavMenuListItem opacity={opacity} active={page === 'books' ? true : false}><a href="/books">Books</a></SubNavMenuListItem>
					<SubNavMenuListItem opacity={opacity} active={page === 'hiking' ? true : false}><a href="/hiking">Hiking</a></SubNavMenuListItem>
					<SubNavMenuListItem opacity={opacity} active={page === 'travels' ? true : false}><a href="/travels">Travels</a></SubNavMenuListItem>
					<SubNavMenuListItem opacity={opacity} active={page === 'blog' ? true : false}><a href="/blog">Blog</a></SubNavMenuListItem>
				</SideBar>*/}
				</BodyContainer>
			</NavContainer>
	)
}

export default Navigation;
