import React from 'react';
import {
	BodyContainer, 
	Image
} from '../../components/StyledComponents/StyledComponents';
import Navigation from '../../components/Navigation/Navigation.jsx';
import Footer from '../../components/Footer/Footer.jsx'
import ProgressBar from '../../components/ProgressBar/ProgressBar';

import headshot from '../../assets/img/slovenia.jpg'

const BlogPost = (props) => {

    return (
		<>
			<ProgressBar />
			<Navigation page="slovenia" />
			<BodyContainer style={{margin: "20%"}}>
			<h1>{props.title}</h1>
			<h4>{props.subtitle}</h4>
			<div style={{ display: "flex", justifyContent: "center" }}>
				<Image
					src={headshot}
					style={{
					margin: "0%",
					objectFit: "cover",
					boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
					}}
				/>
			</div>
			<p>{props.date}</p>

			<p>
			&emsp;Sometimes I grieve for the grandfather I never had. I think about the language, the traditions, the life I could have had, if Deda had been alive. My grandfather was raised in Belgrade by a Slovenian tile setter and a stay-home mother. His father was maimed in World War II; he was a soldier, on the front between Italy and Austria. A messy history, but a happy family. Deda died in 1994 from a poor heart and a poor healthcare system; 8 years before I was born. 

			<br/>&emsp;He used to climb Mt. Triglav. Wars come and go, borders change, but the mountain remains. Triglav is the tallest mountain in the Balkan region. The region was first occupied in the 6th century when King Samo settled with his tribe, known as the Alpine Slavs. For centuries, these people were mountainous. Triglav was first climbed in 1778; this was when Triglav was under the Austro-Hungarian empire. The Slovenes kept climbing. 

			<br/>&emsp;The Slovenians only unifying identity was their language. It wasn’t until World War I that Slovenia became a region, joined by language and slavic traditions. The Unite Slovenia program was successful in 1918, when they joined with the Kingdom of Serbia to become the Kingdom of Yugoslavia, ruled by the Serbian Karadjordjević dynasty. The Slovenians practiced Roman Catholicism, which was distinct in culture from the rest of the Balkan State (who were primarily Orthodox and Muslim). You could say the Slovenians were always a bit of an outcast. 

			<br/>&emsp;World War II did a number on the Slovenian identity. Together with Hitler, the Italians took a large chunk of land from the Slovenians. Mt. Triglav was now under the fascist Italian rule. My ancestors were as well. Deda’s family was very poor; his dad became a soldier to fight at the Italian front. The effort to protect Slovenian land unified the region and gave the people a sense of ethnic pride. With the fall of the Axis Power, the Slovenians got their people back - and their mountain. 

			<br/>&emsp;Welcome to communism. Yugoslavia was poor and the Red Star was appealing for many people. Tito, who was a marshal during the war, became Prime Minister in 1944 and ended up having virtually full power over the country. Tito was named leader for life in 1963; textbook dictator. Tito began to expropriate people’s properties and level society economically. My grandmother’s family home was taken in Belgrade. Corruption was rampant. Unemployment was high. People sometimes weren’t paid. The gender employment gap was large. By nature, communism doesn’t have many companies. Usually, one company would handle all of that related business for the entire country. Energoprojekt was Yugoslavia’s only engineering and construction firm. It was dedicated to rebuilding the country after WWII. Deda was employed as a manager at Energoprojekt. A more stable job in uncertain times. 

			<br/>&emsp;The war of independence from Yugoslavia started on June 25th, 1991; exactly 11 years before I was born. Slovenia was different. It was time to go. After 7 days, Slovenia was named an independent country for the first time in its history. 

			<br/>&emsp;I often ponder: What does it mean to be Slovenian? Providing a definitive answer proves challenging; the only Slovenian I was intimately connected to, died before I was born. 

			<br/>&emsp;Deda was my grandfather, but he was never my grandfather. To me, he was my mother’s father. Others saw him as the Senior Engineer in Energoprojekt, a hiker, or the life of the party.

			<br/>&emsp;Of course not every Slovenian is like my Deda. But there are aspects to him I see in my other Slovenian relatives and friends. The Slovenians are hard workers, but they also host large parties with lamb roasts and wine. The Slovenians have hiked for centuries. The Slovenians treat family as friends and friends as family. The Slovenians are peaceful and full of love. 

			</p>
			</BodyContainer>
			<Footer />
		</>
    );
}

export default BlogPost;