import React, { useState } from 'react';
import {
    Container,
    HeroContainer,
    BodyContainer,
    Image
} from '../StyledComponents/StyledComponents';
import Button from '../Utility/Button';
import headshot from '../../assets/img/Daniela.png'
import InlineLink from '../Utility/Link'
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

const languages = ["English", "한국어", "Српски"]
const titles = ["Hi! I'm Daniela :)", "안녕하세요! 저는 덴녤라예요 :)", "Ћао! Ја сам Даниела :)"]

const HomeHero = () => {
    const [ lang, setLang ] = useState(0);

    return(
        <HeroContainer
            animate={{ opacity: 1 }}
            initial={{ opacity: 0 }}
            transition={{ duration: 2, delay: 1 }}
            exit={{ opacity: 0 }}
            style={{display: "flex", gridTemplateColumns: "45% 45%", gridGap: "5%", opacity: .5, marginLeft: "5%", width: "90%"}} //backgroundImage: `url(${background})`, 
        >            
        
            <BodyContainer style={{display: "flex", flexDirection: "column", flex: "1"}}>
                <Container leftAlign bottom={2}>
                    <h1>{titles[lang]}</h1>
                </Container>
                <Container leftAlign>
                    {lang === 0 && 
                        <p>I'm a proud Seattle-ite and urban planning enthusiast! I’m studying Computer Science + Statistics at Harvard. Catch me reading the next NYTimes bestseller or on top of a mountain discussing the optimal housing policy. Looking forward to meeting you!
<br/>You can read about my <InlineLink route='/hiking' text="hiking"/> and <InlineLink route='/travels' text="travel"/> adventures, <InlineLink route="/books" text="book"/> recommendations, and some of my <InlineLink route='/blog' text="projects"/>. 
                    </p>}
                    {lang === 1 && 
                        <p>안녕하세요, 저는 덴녤라예요! Seattle 사람이에요. 제 정웅은 Computer Science인데요. 저는 하버드에 한국어 학생이에요. 2학기 동안 공부했어요. 저는 <InlineLink route="/books" text="읽고"/>, 음악을 듣고, <InlineLink route='/hiking' text="하이킹하고"/>, <InlineLink route='/travels' text="여행하고"/>, <InlineLink route='/blog' text="코딩하고"/> 좋아요. 만나서 반갑습니다! ^^ 
                    </p>}
                    {lang === 2 && 
                        <p>Moja mama je iz Beograda. Došla je u Ameriku pre rata i sad živi sa mojim tatom u Seattlu. Ne mogu dobro da pričam srpski, ali vežbam svaki dan sa babom :). Sad studiram Computer Science i Statistiku na Harvardu. Volim da <InlineLink route="/books" text="čitam"/>, da vidim <InlineLink route='/travels' text="svet"/>, da idem na <InlineLink route='/hiking' text="planinarenjeda"/>, i da <InlineLink route='/blog' text="programiram"/>.
                    </p>}
                </Container>
                    <Tabs
                        value={lang}
                        onChange={(event, newValue) => setLang(newValue)}
                        variant="scrollable"
                        style={{margin: "5%"}}
                        inkBarStyle={{background: 'blue'}}
                    >
                        {languages.map(type => (
                        <Tab
                            disableRipple
                            label={type || "Untitled"}
                        />
                        ))}
                    </Tabs>
                <a href="/about">
                    <Button
                        route="/about"
                        text="About Me"
                        right
                    />
                </a>
            </BodyContainer>
           
            <Image src={headshot} tag={"home"} style={{margin:"0%", objectFit: "cover",  width: "40%", boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px"}} />
        </HeroContainer>
    )
}

export default HomeHero;
